@font-face {
  font-family: Caveat;
  font-weight: bold;
  src: url('/fonts/Caveat-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: KreonBold;
  font-weight: bold;
  src: url('/fonts/Kreon-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: KreonRegular;
  font-weight: normal;
  src: url('/fonts/Kreon-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: MessengerTexture;
  src: url('/fonts/Messenger-Texture.ttf') format('opentype');
  font-display: swap;
}
:root {
  --orange-peel: #F99B00;
  --cognac: #92341B;
  --cream: #FFEFD1;
  --red: #EC0C11;
  --black: #231F20;
  --grey: #B5B5B5;
  --light-grey: #E8E8E8;
  --green: #01983B;
  --white: #FFFFFF;
}
h1 {
  font-family: Caveat, open-sans;
  font-weight: bold;
  font-size: 40px;
  margin: 0 !important;
}
@media (min-width: 992px) {
  h1 {
    font-size: 65px;
  }
}
h2 {
  font-family: Caveat, open-sans;
  font-size: 32px;
  font-weight: bold;
  margin: 0 !important;
}
@media (min-width: 992px) {
  h2 {
    font-size: 50px;
  }
}
h3 {
  font-family: Caveat, open-sans;
  font-size: 25px;
  font-weight: bold;
  margin: 0 !important;
}
@media (min-width: 992px) {
  h3 {
    font-size: 35px;
  }
}
h4 {
  font-family: Caveat, open-sans;
  font-size: 22px;
  font-weight: bold;
  margin: 0 !important;
}
@media (min-width: 992px) {
  h4 {
    font-size: 25px;
  }
}
.ant-btn.ant-btn,
.ant-btn.ant-btn-default,
.ant-btn.ant-btn-primary,
.ant-btn.ant-btn-ghost {
  font-size: 25px;
  height: 54px;
  font-family: KreonRegular, sans-serif;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.ant-btn.ant-btn-circle {
  width: 66px;
  height: 66px;
}
.ant-btn.ant-btn-text {
  border: none;
  color: var(--orange-peel);
}
.ant-btn.ant-btn-text:hover {
  background-color: #f0f2f5;
  color: var(--cognac);
}
.ant-btn.ant-btn-ghost {
  border: 1px solid var(--orange-peel);
  color: var(--orange-peel);
}
.ant-btn.ant-btn-ghost:hover {
  border: 1px solid var(--cognac);
  color: var(--cognac);
}
.ant-btn.ant-btn-sm {
  font-size: 16px;
  height: 25px;
}
.ant-btn.ant-btn-primary:hover {
  background-color: var(--cognac);
  color: var(--cream);
}
.ant-btn.ant-btn[disabled] {
  border: none !important;
  font-weight: bold;
  background-color: var(--light-grey);
}
.ant-input,
.ant-input-password {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: inherit !important;
  height: 27px;
  border-radius: 0;
  font-size: 18px;
  padding-left: 0;
  padding-right: 0;
  caret-color: var(--orange-peel);
  border-bottom: 1px solid var(--grey);
}
.ant-input.ant-input-password .anticon,
.ant-input-password.ant-input-password .anticon {
  color: var(--orange-peel);
}
.ant-input.ant-input:focus,
.ant-input-password.ant-input:focus {
  box-shadow: none;
  border-bottom: 1px solid var(--black);
}
.ant-input.ant-input-affix-wrapper-focused,
.ant-input-password.ant-input-affix-wrapper-focused {
  min-width: 100%;
  box-shadow: none !important;
  border-right-width: none !important;
}
.ant-input.ant-input-group > :first-child,
.ant-input-password.ant-input-group > :first-child {
  border-radius: 15px !important;
}
.ant-input-affix-wrapper {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}
.ant-input-affix-wrapper .ant-input-prefix {
  color: var(--orange-peel);
}
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.ant-input-affix-wrapper-focused input {
  border: none !important;
}
.ant-select {
  border: none !important;
  border-radius: 0 !important;
}
.ant-select .ant-select-selector {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 1px solid var(--black);
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.ant-popover {
  max-width: 282px;
}
.ant-popover .ant-popover-title {
  color: var(--orange-peel) !important;
  border: none;
}
.ant-popover .ant-btn {
  height: 37px;
  width: 204px;
  margin: 10px 0;
}
.ant-radio-checked + span {
  transition: 0.2s ease all;
  color: var(--orange-peel) !important;
}
.ant-message .error-banner .ant-message-notice-content {
  width: 540px;
  padding: 13px 0 0 28px;
  background-color: #fedfe3;
  border-radius: 15px;
  position: relative;
  min-height: 89px;
  max-height: 120px;
}
.ant-message .error-banner .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  text-align: left;
}
.ant-message .error-banner .ant-message-notice-content h3 {
  color: var(--red);
  font-size: 25px;
  margin: 0;
}
.ant-message .success-banner .ant-message-notice-content {
  width: 540px;
  padding: 13px 0 0 28px;
  border-radius: 15px;
  background-color: var(--white);
  position: relative;
  min-height: 89px;
  max-height: 120px;
}
.ant-message .success-banner .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  text-align: left;
}
.ant-message .success-banner .ant-message-notice-content h3 {
  color: var(--green);
  font-size: 25px;
  margin: 0;
}
.ant-message .success-banner .ant-message-notice-content svg {
  color: var(--green);
}
@media only screen and (max-width: 768px) {
  .ant-message .success-banner .ant-message-notice-content {
    width: 350px;
  }
}
.ant-message .info-banner .ant-message-notice-content {
  width: 540px;
  padding: 13px 0 0 28px;
  border-radius: 15px;
  background-color: var(--white);
  position: relative;
  min-height: 89px;
  max-height: 120px;
}
.ant-message .info-banner .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}
.ant-message .info-banner .ant-message-notice-content h3 {
  color: #f8a023;
  margin: 0;
  font-size: 25px;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}
.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.m-6 {
  margin: 3.5rem !important;
}
.mt-6 {
  margin-top: 3.5rem !important;
}
.mb-6 {
  margin-bottom: 3.5rem !important;
}
.my-6 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}
.ml-6 {
  margin-left: 3.5rem !important;
}
.mr-6 {
  margin-right: 3.5rem !important;
}
.mx-6 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}
.m-7 {
  margin: 4rem !important;
}
.mt-7 {
  margin-top: 4rem !important;
}
.mb-7 {
  margin-bottom: 4rem !important;
}
.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.ml-7 {
  margin-left: 4rem !important;
}
.mr-7 {
  margin-right: 4rem !important;
}
.mx-7 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
.mt-10 {
  margin-top: 6rem !important;
}
.mb-10 {
  margin-bottom: 6rem !important;
}
.my-10 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}
.ml-10 {
  margin-left: 6rem !important;
}
.mr-10 {
  margin-right: 6rem !important;
}
.mx-10 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}
.mt-20 {
  margin-top: 12rem !important;
}
.mb-20 {
  margin-bottom: 12rem !important;
}
.my-20 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}
.ml-20 {
  padding-left: 12rem !important;
}
.mr-20 {
  padding-right: 12rem !important;
}
.mx-20 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pt-10 {
  padding-top: 6rem !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.pl-4 {
  padding-left: 1.5rem !important;
}
.pr-4 {
  padding-right: 1.5rem !important;
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pl-5 {
  padding-left: 3rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}
.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
html,
body {
  height: 100%;
  font-family: KreonRegular, sans-serif;
  font-size: 18px;
}
html .custom-scroll___1GL5-::-webkit-slider-thumb,
body .custom-scroll___1GL5-::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  background-color: var(--orange-peel) !important;
}
body {
  font-family: KreonRegular, sans-serif;
  font-size: 16px;
}
@media (min-width: 992px) {
  body {
    font-size: 18px;
  }
}
.form-item-default {
  height: 77px;
}
.default-btn-size {
  height: 40px !important;
}
.default-box {
  background-color: #ffffff;
  box-shadow: 0 22px 24px -30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
p {
  margin-bottom: 0 !important;
}
.paragraph-break p {
  margin-bottom: 8px !important;
}
.paragraph-justfiy {
  text-align: justify;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-grey {
  color: var(--grey);
}
.text-black {
  color: #000000 !important;
}
.text-orange-peel {
  color: var(--orange-peel);
}
.text-error {
  color: var(--red);
}
.text-cognac {
  color: var(--cognac);
}
.kreon-bold {
  font-family: KreonBold, sans-serif;
}
.center-vh {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.end-vh {
  display: flex !important;
  justify-content: left !important;
  align-items: flex-end !important;
}
.center-left-vh {
  display: flex !important;
  justify-content: left !important;
  align-items: center !important;
}
.center-right-vh {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
.divider {
  border-bottom: 1px solid var(--grey);
  margin: 10px 0;
  width: 100;
}
.subtitle {
  font-family: KreonBold, sans-serif;
  font-size: 12px;
}
@media (min-width: 992px) {
  .subtitle {
    font-size: 14px;
  }
}
.cognac-btn {
  background-color: var(--cognac);
  color: var(--cream);
  border: none !important;
}
.cognac-btn:hover {
  background-color: var(--cognac);
  color: var(--cream);
}
.cream-btn {
  background-color: var(--cream);
  color: var(--cognac);
  border: none !important;
}
.cream-btn:hover {
  color: var(--cream);
  background-color: var(--cognac);
}
.cognac-outlined {
  border: 1px solid var(--cognac) !important;
  color: var(--cognac) !important;
}
.social-media-btn {
  border-radius: 50px;
  border: 1px solid #b5b5b5;
  background-color: var(--white);
  width: 155px;
  font-size: 16px !important;
  justify-content: center !important;
}
.social-media-btn img {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}
.social-media-btn:hover {
  color: var(--cognac);
  border: 1px solid var(--cognac);
}
@media (min-width: 768px) {
  .social-media-btn {
    min-height: 65px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.shadow {
  box-shadow: 0 8px 20px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px;
}
.bonanza-btn {
  background: #fff;
  color: #bb0706;
  height: 35px !important;
  border-radius: 7px;
}
.bonanza-btn:hover {
  background: #bb0706 !important;
  color: #fff !important;
  border: 0 !important;
}
.bonanza-btn span {
  font-size: 14px;
}
